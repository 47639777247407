const $ = window.jQuery;

export default class CoDialog {
  constructor() {
    this.dialogs = {
      email_in_use: {
        id: 'co_email_in_use',
        buttons: {
          ok: '#co_email_in_use_login',
          cancel: '#co_email_in_use_cancel',
        },
      },
    };
  }

  init() {
    this.emailInUse();
  }

  emailInUse() {
    const thisDialogs = this.dialogs.email_in_use;
    const { id } = thisDialogs;
    const { ok, cancel } = thisDialogs.buttons;
    if (ok) {
      $('body').on('click', ok, () => {
        document.getElementById(id).close();
        if (!$('.woocommerce-form-login').is(':visible')) {
          $('.woocommerce-form-login-toggle .showlogin').trigger('click');
        }
        $('.woocommerce-form-login #username').val(
          $('input#billing_email').val(),
        );
        $('.woocommerce-form-login #password').focus();

        $('input#billing_email').val('');

        $('body, html').animate(
          {
            scrollTop:
              $('.woocommerce-form-login-toggle:first').offset().top - 150,
          },
          500,
        );
      });
    }
    if (cancel) {
      $('body').on('click', cancel, () => {
        document.getElementById(id).close();
        $('input#billing_email')
          .val('')
          .focus();
      });
    }
  }
}
